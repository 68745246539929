<template>
  <div class="home">
    <NavLinkBar class="NavLinkBar" text-color="white"></NavLinkBar>
      <div class="content">
        <div class="line-left">
            <h1 class="title-h1">PROFESSIONALS</h1>
            <h2 class="title-h2"> OF REPAIR WORKS OF<br>ANY COMPLEXITY</h2>
            <p class="description">
                We provide a wide range of repair works. Starting from the redevelopment of the house - ending with the installation of windows and doors, as well as legalization of works after their completion.
            </p>
        </div>
         <button class="cta-button" @click="submitRequest">Submit a request</button>
      </div>
  </div>
  <AboutUsHome></AboutUsHome>
  <ServicesHome></ServicesHome>
  <OurProjectsHome></OurProjectsHome>
  <FeedBacksHome></FeedBacksHome>
  <SabmitReqHome  id="request-form"></SabmitReqHome>
  <FooterEl></FooterEl>
</template>

<script>

import NavLinkBar from '@/components/navbar/NavLinkBar.vue'
import AboutUsHome from './homeComponents/AboutUsHome.vue'
import ServicesHome from './homeComponents/ServicesHome.vue'
import OurProjectsHome from './homeComponents/OurProjectsHome.vue'
import FeedBacksHome from './homeComponents/FeedBacksHome.vue'
import SabmitReqHome from './homeComponents/SabmitReqHome.vue'
import FooterEl from '@/components/footerAndLeg/Footer.vue'

export default {
  components: {
    NavLinkBar,
    AboutUsHome,
    ServicesHome,
    OurProjectsHome,
    FeedBacksHome,
    SabmitReqHome,
    FooterEl
  },
  name: 'HomePage',
  mounted () {
    document.title = 'VS Custom Remodeling'
  },
  methods: {
    submitRequest () {
      this.$router.push({ name: 'Home' }).then(() => {
        this.$nextTick(() => {
          const element = document.getElementById('request-form')
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' })
          }
        })
      })
    }
  }
}
</script>

<style scoped>
.home {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('@/assets/bg/homeBeck.png');
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center; /* This will vertically center your .content div */
  justify-content: flex-start; /* This will align your .content div to the left */
  font-size: 1vw;
}
.NavLinkBar {
  top: 0;
  left: 0;
  width: 100%;
  height: 25%;
  display: flex;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.01) 100%);
  color: white; /* Assuming you want white text for contrast */
  position: absolute;
  align-items: stretch;
}
.content {
  user-select: none;
  margin-top: 11%; /* Adjust padding to match the exact position in the design */
  width: 53%;
  display: flex;
  flex-direction: column;
  color: white;
  justify-content: center;
}

.line-left {
  border-left: 0.5vw solid orange;
  padding-left: 2vw;
  margin-left: 3vw;
  display: flex;
  flex-direction: column;
  height: auto;
}
.title-h1 {
  color: #FFA500; /* Orange color, adjust to match the exact shade from your image */
  font-family: 'Impact', sans-serif; /* Impact is a commonly used font for bold headings, may need adjustment */
  letter-spacing: 1vw; /* Spacing between letters, adjust as needed */
  text-transform: uppercase; /* Uppercase text */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Text shadow for depth, adjust as needed */
  -webkit-text-stroke: 1px black; /* Outline effect for text, not supported in all browsers */
  max-height: 100%; /* ensures the child div is not taller than the parent */
  font-size: 5vw;
  overflow: auto;
  margin: 0%;
}
.title-h1::-webkit-scrollbar {
  display: none; /* Скрывает ползунок */
  margin: 0%;
}
.title-h2 {
  color: #FFFFFF; /* White color */
  font-family: 'Arial', sans-serif; /* Arial font, a common choice for sans-serif text */
  letter-spacing: 1px; /* Spacing between letters, adjust as needed */
  text-transform: uppercase; /* Uppercase text */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  max-height: 100%; /* ensures the child div is not taller than the parent */
  overflow: auto;
  font-size: 3vw;
  margin: 1vh;
}
.title-h2::-webkit-scrollbar {
  display: none; /* Скрывает ползунок */
}
.description {
  font-family: 'Arial', sans-serif; /* Arial font, a common choice for sans-serif text */
  font-size: 1.6vw;
  color: #fff;
  max-height: 100%; /* ensures the child div is not taller than the parent */
  margin: 1vh;
}
.description::-webkit-scrollbar {
  display: none; /* Скрывает ползунок */
}
.cta-button {
  margin: 3%;
  background-color: #FFA500;
  color: #060606; /* White text */
  border: none; /* No border */
  font-family: Arial, sans-serif; /* Default font, adjust as needed */
  padding: 1vh 2.5vw; /* Use viewport units to scale padding */
  font-size: 1.6vw;
  width: 40%;
  transform: translateX(+60%);
  font-weight: bold; /* Bold font */
  text-transform: uppercase; /* Uppercase text */
  border-radius: 30px; /* Rounded corners, adjust as needed */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow effect, adjust as needed */
  cursor: pointer; /* Cursor changes to a hand symbol when hovering over the button */
  transition: all 0.3s ease; /* Smooth transition for hover effects */
}
.FooterEl {
  width: 100%;
  height: 10vw;
  display: flex;
  position: absolute;
  align-items: stretch;
}
</style>

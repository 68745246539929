<template>
  <div class="request-form-container">
    <div class="text-con">
      <h2 class="REQH2">SUBMIT A REQUEST</h2>
      <p class="reqp">
        Leave your request through<br>
        our form, and we'll get back<br>
        to you in the next 24 hours!<br><br>
        If you can't decide on a<br>
        service or want a complex of<br>
        services - just write it in<br>
        the comments❤️
      </p>
    </div>
    <div class="formIn">
      <form @submit.prevent="submitForm1">
        <div class="input-group">
          <input v-model="requestForm.fullName" type="text" required placeholder="Full Name*" />
          <div class="input-group half-width-inputs">
            <input v-model="requestForm.phoneNumber" type="tel" required placeholder="Phone number*" />
            <input v-model="requestForm.email" type="email" required placeholder="Email*" />
          </div>
        </div>
        <div class="select-group">
          <select v-model="requestForm.service" required>
            <option disabled value="">Select the service</option>
            <option value="int">Interior Works</option>
            <option value="ext">Exterior Works</option>
            <option value="LL">Licence&Legalizations</option>
            <option value="O">Other</option>
          </select>
        </div>
        <div class="textarea-group">
          <textarea v-model="requestForm.comments" placeholder="Comments"></textarea>
        </div>
        <button type="submit">SEND REQUEST</button>
        <div v-if="isSubmitted" class="modal">
          <div class="modal-content">
            <p>{{ submitMessage }}</p>
            <button @click="closeModal">OK</button>
          </div>
       </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      requestForm: {
        fullName: '',
        phoneNumber: '',
        email: '',
        service: '',
        comments: ''
      },
      isSubmitted: false, // flag to show the message
      submitMessage: '' // message to the user
    }
  },
  methods: {
    submitForm1 () {
      const botToken = '6903538128:AAEf92LLtjalfxbt2zpS_IquGO2_awHbtcM' // Replace with your actual bot token
      const chatId = '-1002033490321' // Replace with your actual chat ID
      const message = `
        Full Name: ${this.requestForm.fullName}
        Phone: ${this.requestForm.phoneNumber}
        Email: ${this.requestForm.email}
        Service: ${this.requestForm.service}
        Comments: ${this.requestForm.comments}
      `
      axios.post(`https://api.telegram.org/bot${botToken}/sendMessage?chat_id=${chatId}&text=${message}`)
        .then(response => {
          console.log('Message sent:', response.data)
          this.isSubmitted = true
          this.submitMessage = 'Your request has been sent successfully!'
          // Reset the form
          this.requestForm = {
            fullName: '',
            phoneNumber: '',
            email: '',
            service: '',
            comments: ''
          }
        })
        .catch(error => {
          console.error('Error sending message:', error)
          this.submitMessage = 'Failed to send the request. Please try again later.'
        })
    },
    closeModal() {
      this.isSubmitted = false;
    }
  }
}
</script>
<style scoped>
.request-form-container {
    width: 100vw;
    height: auto;
    display: flex;
    height: auto;
    background-color: gainsboro;
    flex-direction: row;
  }
.text-con{
    margin: 5vh;
    flex: 4;
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content: center;
}
.REQH2{
  margin-top: 4vh;
  font-weight: bold;
  font-size: 3vw;
}
.reqp{
  font-weight: bold;
  font-size: 2vw;
  margin-top: 6vh;
  margin-bottom: 5%;
}
.formIn {
  flex: 6;
  height: auto;
  padding: 5vh;
  text-align: center;
  justify-content: center;
}

.input-group, .select-group, .textarea-group {
  margin-bottom: 1.5vh;
}

.input-group input, .select-group select, .textarea-group textarea {
  width: 100%;
  padding: 1.2vh;
  margin: 0.5vh 0;
  box-sizing: border-box;
  background: transparent; /* Sets the background to transparent */
  padding-bottom: 2%;
  padding-top: 2%;
  padding-left: 2%;
  padding-right: 2%;
  border: 2px solid #020202;
}
.half-width-inputs {
  display: flex;
  gap: 1%; /* Adjust the gap to your liking */
}

.half-width-inputs input {
  width: 49.5%; /* Adjusting for the gap */
}
.button-group {
  text-align: center;
}
textarea{
    height: 30vh;
}

button {
  width: 60%;
  height: auto;
  border: none;
  box-sizing: border-box;
  margin-top: 1rem;
  margin: 1%;
  background-color: #FFA500;
  color: #060606;
  padding: 2vh 3.5vw; /* Use viewport units to scale padding */
  font-size: 2.5vw;
  font-weight: bold; /* Bold font */
  text-transform: uppercase; /* Uppercase text */
  border-radius: 30px; /* Rounded corners, adjust as needed */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow effect, adjust as needed */
  cursor: pointer; /* Cursor changes to a hand symbol when hovering over the button */
  transition: all 0.3s ease; /* Smooth transition for hover effects */
  font-family: Arial, sans-serif; /* Default font, adjust as needed */

}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.modal-content p {
  margin-bottom: 15px;
}

</style>
import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/components/home/Home.vue';
import ContactPage from '@/components/contacts/Contacts.vue'
import AboutUsPage from '@/components/aboutUs/AboutUs.vue'
import ServicesPage from '@/components/services/Services.vue'
import EWPage from '@/components/services/exteriorWorks/ExteriorWorks.vue'
import EDPage from '@/components/services/exteriorWorks/allPages/ExtDec.vue'
import CWPage from '@/components/services/exteriorWorks/allPages/ConcWorks.vue'
import PCPage from '@/components/services/exteriorWorks/allPages/PatConstr.vue'
import EPPage from '@/components/services/exteriorWorks/allPages/ExtPaint.vue'
import IWPage from '@/components/services/internalWorks/InternalWorks.vue'
import HEPage from '@/components/services/internalWorks/allPages/HomeExt.vue'
import IPPage from '@/components/services/internalWorks/allPages/InteriorPainting.vue'
import HRRPage from '@/components/services/internalWorks/allPages/HomeRemRen.vue'
import KBPage from '@/components/services/internalWorks/allPages/KitchenBathroom.vue'
import KCPage from '@/components/services/internalWorks/allPages/KitchenCabinets.vue'
import PWFIPage from '@/components/services/internalWorks/allPages/PlumbingWorkFixtureInst.vue'
import WIPage from '@/components/services/internalWorks/allPages/WaterInstrep.vue'
import WDPage from '@/components/services/internalWorks/allPages/WindowsDoors.vue'
import PELPage from '@/components/services/licenceLegalizations/PEL.vue'
import PRPage from '@/components/services/licenceLegalizations/allPages/PR.vue'
import EPMPage from '@/components/services/licenceLegalizations/allPages/EPM.vue'
import LPage from '@/components/services/licenceLegalizations/allPages/Legalizing.vue'
import PaT from '@/components/footerAndLeg/PrivcTerm.vue'
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/PolicyAndTerms',
    name: 'PaT',
    component: PaT
  },
  {
    path: '/contacts',
    name: 'Contact',
    component: ContactPage
  },
  {
    path: '/services',
    name: 'Services',
    component: ServicesPage
  },
  {
    path: '/services/ExteriorWorks',
    name: 'ExteriorWorks',
    component: EWPage
  },
  {
    path: '/services/ExteriorWorks/ExteriorPaintin',
    name: 'ExteriorPaintin',
    component: EPPage,
    props: true
  },
  {
    path: '/services/ExteriorWorks/ExteriorDecoration',
    name: 'ExtDec',
    component: EDPage,
    props: true
  },
  {
    path: '/services/ExteriorWorks/ConcreteWorks',
    name: 'ConcreteWorks',
    component: CWPage,
    props: true
  },
  {
    path: '/services/ExteriorWorks/PatioConstruction',
    name: 'PatioConstruction',
    component: PCPage,
    props: true
  },
  {
    path: '/services/InteriorWorks',
    name: 'InteriorWorks',
    component: IWPage
  },
  {
    path: '/services/InteriorWorks/HomeExtention',
    name: 'HomeExt',
    component: HEPage,
    props: true
  },
  {
    path: '/services/InteriorWorks/HomeRemodelingAndRenovation',
    name: 'HomeRemodelingAndRenovation',
    component: HRRPage,
    props: true
  },
  {
    path: '/services/InteriorWorks/InteriorPainting',
    name: 'InteriorPainting',
    component: IPPage,
    props: true
  },
  {
    path: '/services/InteriorWorks/KitchenBathroom',
    name: 'KitchenBathroom',
    component: KBPage,
    props: true
  },
  {
    path: '/services/InteriorWorks/PlumbingWorkFixtureInst',
    name: 'PlumbingWorkFixtureInst',
    component: PWFIPage,
    props: true
  },
  {
    path: '/services/InteriorWorks/KitchenCabinets',
    name: 'KitchenCabinets',
    component: KCPage,
    props: true
  },
  {
    path: '/services/InteriorWorks/WaterInstrep',
    name: 'WaterInstrep',
    component: WIPage,
    props: true
  },
  {
    path: '/services/InteriorWorks/WindowsDoors',
    name: 'WindowsDoors',
    component: WDPage,
    props: true
  },
  {
    path: '/services/PermitExpeditorLegalizations',
    name: 'PermitExpeditorLegalizations',
    component: PELPage,
    props: true
  },
  {
    path: '/services/PermitExpeditorLegalizations/PermitRunning',
    name: 'PRPage',
    component: PRPage,
    props: true
  },
  {
    path: '/services/PermitExpeditorLegalizations/EPM',
    name: 'EPMPage',
    component: EPMPage,
    props: true
  },
  {
    path: '/services/PermitExpeditorLegalizations/LegalizingAfterFactWork',
    name: 'LPage',
    component: LPage,
    props: true
  },
  {
    path: '/aboutUs',
    name: 'AboutUs',
    component: AboutUsPage
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});
export default router;

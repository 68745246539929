<template>
  <div class="section-background EW" id="EW">
    <div class="button-container" @mouseover="toggleVisibility($event, 0)" @mouseout="toggleVisibility($event, 0)">
      <router-link to="/services/ExteriorWorks" class="plain-link">
        <h1 class="mainT">EXTERIOR WORKS</h1>
      </router-link>
      <transition name="slide-fade">
        <div class="list">
          <ul>
          <li><router-link to="/services/ExteriorWorks/ConcreteWorks">Concrete Works</router-link></li>
          <li><router-link to="/services/ExteriorWorks/ExteriorPaintin">Exterior Painting</router-link></li>
          <li><router-link to="/services/ExteriorWorks/PatioConstruction">Patio Construction</router-link></li>
          <li><router-link to="/services/ExteriorWorks/ExteriorDecoration">Exterior Decoration</router-link></li>
          </ul>
        </div>
      </transition>
      <div class="arrow-icon">
      </div>
   </div>
  </div>
  <div class="section-background IW" id="IW">
    <div class="button-container"  @mouseover="toggleVisibility($event, 1)" @mouseout="toggleVisibility($event, 1)">
      <router-link to="/services/InteriorWorks" class="plain-link">
        <h1 class="mainT">INTERNAL WORKS</h1>
      </router-link>
      <transition name="slide-fade">
        <div class="list">
          <ul>
          <li><router-link to="/services/InteriorWorks/InteriorPainting">Interior Painting</router-link></li>
          <li><router-link to="/services/InteriorWorks/KitchenCabinets">Kitchen Cabinets</router-link></li>
          <li><router-link to="/services/InteriorWorks/HomeExtention">Home Extensions</router-link></li>
          <li><router-link to="/services/InteriorWorks/WindowsDoors">Windows and Doors</router-link></li>
          <li><router-link to="/services/InteriorWorks/KitchenBathroom">Kitchen & Bathroom Remodel</router-link></li>
          <li><router-link to="/services/InteriorWorks/HomeRemodelingAndRenovation">Home Remodeling and Renovation</router-link></li>
          <li><router-link to="/services/InteriorWorks/PlumbingWorkFixtureInst">Plumbing Work & Plumbing Fixture Installation</router-link></li>
          <li><router-link to="/services/InteriorWorks/WaterInstrep">Water Heater Installation & Water Piping Repair</router-link></li>
          </ul>
        </div>
      </transition>
      <div class="arrow-icon">
      </div>
   </div>
  </div>
  <div class="section-background LAL" id="LL">
    <div class="button-container" @mouseover="toggleVisibility($event, 2)" @mouseout="toggleVisibility($event, 2)">
      <router-link to="/services/PermitExpeditorLegalizations" class="plain-link">
        <h1 class="mainT">LICENCE AND LEGALIZATIONS</h1>
      </router-link>
      <transition name="slide-fade">
        <div class="list">
          <ul>
          <li><router-link to="/services/PermitExpeditorLegalizations/PermitRunning">Permit Running</router-link></li>
          <li><router-link to="/services/PermitExpeditorLegalizations/EPM">Electrical, Plumbing G permits, Mechanical Permits</router-link></li>
          <li><router-link to="/services/PermitExpeditorLegalizations/LegalizingAfterFactWork">Legalizing After the Fact Work</router-link></li>
          </ul>
        </div>
      </transition>
      <div class="arrow-icon">
      </div>
   </div>
  </div>
</template>
<script>
export default {
  name: 'ServicesHome',
  data () {
    return {
      sectionVisibility: [false, false, false]
    }
  },
  methods: {
    toggleVisibility (event, sectionIndex) {
      if (event.type === 'mouseover') {
        this.expandDiv(sectionIndex)
      } else if (event.type === 'mouseout') {
        this.shrinkDiv(sectionIndex)
      }
    },
    expandDiv (sectionIndex) {
      this.sectionVisibility[sectionIndex] = true
    },
    shrinkDiv (sectionIndex) {
      this.sectionVisibility[sectionIndex] = false
    }
  }
}
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");
.section-background {
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: flex;
    position: relative;
}
.EW {
    background-image: url('@/assets/bg/EW.png');
}

.IW {
    background-image: url('@/assets/bg/IW.png');
}

.LAL {
    background-image: url('@/assets/bg/LAL.png');
}
.button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 15%;
    background-color: rgba(255, 166, 0, 0.767);
    text-align: center;
    transition: height 0.8s ease;
}

.button-container h1 {
    position: absolute;
    top: 0;
    left: 0;
    margin: 3vh;
}

.button-container:hover {
    height: 70%;
}
.mainT{
  font-size: 4vw;
  color: rgb(255, 255, 255);
  padding-left: 1%;
}
.list {
  position: absolute;
  opacity: 0;
  transition: 0.5s ease;
}
.button-container:hover .list {
  opacity: 1;
  transition: opacity 0.5s ease 0.5s; /* Reduced delay */
}
.list ul li {
  font-size: 4.5vh;
  font-weight: bold;
  position: relative;
  color: #fce4ec;
  list-style: none;
  margin: 4px 0;
  cursor: pointer;
  overflow: hidden; /* Ensures the pseudo-element's transform is contained within the li bounds */
}

.list ul li:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: rgb(255, 166, 0);
  transform-origin: center;
  transform: scaleX(0);
  transition: transform 1s ease;
  z-index: 1; /* Ensure this is behind the router-link text */
}

.list ul li:hover:before {
  transform: scaleX(1);
}
.list ul li span {
  position: relative;
  width: 100%;
  padding: 8px;
  padding-left: 12px;
  display: inline-block;
  z-index: 1;
  transition: 1s;
}
.list ul li:hover span {
  color: #111;
}
.list ul li a {
  color: #fce4ec; /* Adjusted font color */
  text-decoration: none;
  display: block;
  position: relative; /* Make sure it has a position context for z-index */
  z-index: 2; /* Higher than its :before pseudo-element */
}

.list ul li a:hover {
  color: #111; /* Color change on hover */
}
@keyframes zoomIn {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
.arrow-icon {
  position: absolute;
  bottom: 2vh;
  right: 2vw;
  width:  5vw; /* Arrow size */
  height: 10vh; /* Arrow size */
  border: 1vh solid rgb(255, 255, 255); /* Circle border */
  border-radius: 50%; /* Make it round */
  display: flex;
  align-items: center;
  justify-content: center;
}
.arrow-icon:before {
  content: '';
  display: block;
  width: 2vw; /* Arrow width */
  height: 2vw; /* Arrow height */
  border-right: 1vh solid rgb(255, 255, 255); /* Right part of arrow */
  border-top: 1vh solid rgb(255, 255, 255); /* Top part of arrow */
  transform: rotate(45deg); /* Angle the arrow */
  position: relative;
  z-index: 0; /* Ensure arrow is above the stick */
}
.arrow-icon:after {
  content: '';
  display: block;
  position: absolute;
  width: 3vw; /* Stick width */
  height: 1vh; /* Stick height */
  background-color: rgb(255, 255, 255); /* Stick color */
  left: 1vw;
  top: 50%; /* Positioning at the center of the circle */
  right: 50%;

  transform: translateY(-50%); /* Ensuring it's centered vertically */
  z-index: 0; /* Below the arrow head */
}
.button-container:hover .arrow-icon:before {
  transform: rotate(-45deg);
}
.button-container:hover .arrow-icon:after {
  transform: rotate(-90deg);
}
/* Transition for the arrow's rotation */
.arrow-icon:before, .arrow-icon:after {
  transition: transform 0.9s ease;
}
.plain-link, .plain-link:visited {
  text-decoration: none; /* Removes underline */
  color: inherit; /* Inherits the color from parent elements */
}

.plain-link:hover, .plain-link:focus {
  text-decoration: none; /* Keeps underline removed on hover/focus */
  color: inherit; /* Ensures the color doesn't change on hover/focus */
}
</style>

<template>
    <div class="mainClass">
        <NavLinkBar class="NavLinkBar"></NavLinkBar>
        <div class="nav_page">
          <h1 class="maintitle">PERMIT EXPRDITOR LEGALIZATION</h1>
          <div class="arrow-icon" @click="navigateToPage()"></div>
          <div class="blokE"></div>
        </div>
        <div class="mainContent">
          <div class="list">
                  <ul>
                      <li><span>
                          <router-link to="/services/PermitExpeditorLegalizations/PermitRunning" class="plain-link">
                              <h1 class="mainT">PermitRunning</h1>
                          </router-link>
                      </span></li>
                      <li><span>
                          <router-link to="/services/PermitExpeditorLegalizations/EPM" class="plain-link">
                              <h1 class="mainT">Electrical, Plumbing G permits, Mechanical Permits</h1>
                          </router-link>
                      </span></li>
                      <li><span>
                          <router-link to="/services/PermitExpeditorLegalizations/LegalizingAfterFactWork" class="plain-link">
                              <h1 class="mainT">Legalizing After the Fact Work</h1>
                          </router-link>
                      </span></li>
                  </ul>
              </div>
        </div>
    </div>
  </template>

<script>

import NavLinkBar from '@/components/navbar/NavLinkBar.vue'
export default {
  name: 'PELPage',
  components: {
    NavLinkBar
  },
  methods: {
    navigateToPage () {
      this.$router.push({ name: 'Services' })
      }
  }
}
</script>

<style scoped>
  .mainClass{
    user-select: none;
    height: 100vh;
    width: 100vw;
    display: flex;
    background-image: linear-gradient( rgba(0, 0, 0, 0.5),  rgba(0, 0, 0, 0.5)), url('@/assets/bg/EW.png');
    background-size: cover;
    flex-direction: column;
  }
  .NavLinkBar {
    top: 0;
    left: 0;
    width: 100%;
    height: 25%;
    display: flex;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.01) 100%);
    color: white; /* Assuming you want white text for contrast */
    position: absolute;
    align-items: stretch;
}
  .nav_page {
    margin-top: 23vh;
    display: flex;
    width: 100%;
    height: 20%;
    background-color: rgb(255, 166, 0);
    display: flex;
    align-items: center;
    justify-items: center;
  }
  .maintitle{
    flex: 5;
    color: #fff;
    margin: 0;
    margin-left: 1vw;
    text-align: left;
    font-size: 9vh;
  }
  .arrow-icon {
    position: absolute;
    margin-right: 1%;
    right: 1vw; /* Position the arrow 1vw from the right edge */
    width: 3.7vw; /* Arrow size */
    height: 7.4vh; /* Arrow size */
    border: 1vh solid rgb(255, 255, 255); /* Circle border */
    border-radius: 50%; /* Make it round */
    display: flex;
    align-items: center;
    justify-content: center;
}

.arrow-icon:before {
    content: '';
    display: block;
    width: 1.6vw; /* Arrow width */
    height: 1.6vw; /* Arrow height */
    border-left: 1vh solid rgb(255, 255, 255); /* Right part of arrow */
    border-bottom: 1vh solid rgb(255, 255, 255); /* Top part of arrow */
    transform: rotate(45deg); /* Angle the arrow */
    position: relative;
    z-index: 0; /* Ensure arrow is above the stick */
}

.arrow-icon:after {
    content: '';
    display: block;
    position: absolute;
    width: 2.2vw; /* Stick width */
    height: 1vh; /* Stick height */
    background-color: rgb(255, 255, 255); /* Stick color */
    left: 1vw;
    top: 50%; /* Positioning at the center of the circle */
    right: 50%;
    transform: translateY(-50%); /* Ensuring it's centered vertically */
    z-index: 0; /* Below the arrow head */
}
.mainContent{
    width: 100%;
    height: 100%;
    background: #dbdada;
    flex-direction: column;
    display: flex;
}
h1{
  font-size: 10vh;
}
.mainT{
    font-size: 7vh;
    margin: 1vh;
}
  .list {
      text-align: center;
      margin: auto; /* Further ensures the list is centered horizontally in the flex container */
      position: relative; /* Adjusted for better control over positioning */
      display: flex; /* Using flexbox to align list items */
      flex-direction: column; /* Aligns children (ul) in a column */
      justify-content: center; /* Centers the ul vertically in the list */
      align-items: center; /* Centers the ul horizontally in the list */
  }
  .button-container:hover .list {
      opacity: 1;
      transition: opacity 0.5s ease 0.5s; /* Reduced delay */
  }
  .list ul li {
      position: relative; /* Ensure the pseudo-element is positioned relative to the li */
      overflow: hidden; /* Added to contain the pseudo-elements within the li bounds */
  }

  .list ul li:before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%; /* Start from the center */
      height: 100%;
      width: 0; /* Start with no width */
      background-color: rgb(255, 166, 0); /* Your highlight color */
      z-index: 0;
      transition: width 0.5s ease, left 0.5s ease; /* Animate width and horizontal position */
      transform-origin: center; /* Ensures scaling happens from the center */
  }

  .list ul li:hover:before {
      width: 100%; /* Expand to full width */
      left: 0; /* Reset left to cover the whole item */
  }

  .list ul li span {
      position: relative;
      z-index: 1; /* Ensure the text remains above the pseudo-element */
      display: block;
      transition: color 0.3s; /* Smooth color transition for text */
  }

  .list ul li:hover span {
      color: #fdfdfd; /* Text color change on hover */
  }
  .plain-link, .plain-link:visited, .plain-link:hover, .plain-link:focus {
      text-decoration: none; /* Removes underline */
      color: #000000; /* Change color to match your design, here it's set to black */
  }

  .list ul li:hover .plain-link {
      color: #fdfdfd; /* Optional: Text color change on hover, matching your existing :hover state */
  }
  @keyframes zoomIn {
    from {
      transform: scale(0);
      opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }
  </style>

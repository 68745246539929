<template>
  <div class="drk">
    <div class="OP">
      <div class="hdr">
        <h1>OUR PROJECTS</h1>
      </div>
      <div class="content">
        <div class="navBut">
          <button class="back-button" @click="goBack">
            <img :src="btnLeft" alt="Go back" />
          </button>
        </div>
        <div class="iconAndButton">
          <img :src="iconop" alt="Project icon">
          <button class="cta-button" @click="submitRequest">Submit a request</button>
        </div>
        <div class="navBut">
          <button class="back-button" @click="goForward">
            <img :src="btnRight" alt="Go forward" />
          </button>
        </div>
      </div>
    </div>
  </div>
    </template>

<script>

import iconOP from '@/assets/ourProjects/oppic.png'
import iconOP1 from '@/assets/ourProjects/oppic1.png'
import iconOP2 from '@/assets/ourProjects/oppic2.png'
import iconOP3 from '@/assets/ourProjects/oppic3.png'
import btnL from '@/assets/btn/btnLeft.png'
import btnR from '@/assets/btn/btnRight.png'

export default {
  name: 'OurProjects',
  data () {
    return {
      iconop: iconOP, // Initial icon
      icons: [iconOP,iconOP1, iconOP2, iconOP3], // Array of icons
      currentIndex: 0,
      btnLeft: btnL,
      btnRight: btnR
    }
  },
  methods: {
    goBack () {
      this.currentIndex = (this.currentIndex - 1 + this.icons.length) % this.icons.length
      this.iconop = this.icons[this.currentIndex]
      console.log('Go back action triggered')
    },
    goForward () {
      this.currentIndex = (this.currentIndex + 1) % this.icons.length
      this.iconop = this.icons[this.currentIndex]
      console.log('Go forward action triggered')
    },
    submitRequest () {
      this.$router.push({ name: 'Home' }).then(() => {
        this.$nextTick(() => {
          const element = document.getElementById('request-form')
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' })
          }
        })
      })
    }
  }
}
</script>

<style scoped>

.drk{
  height: 100vh;
  background-image: url('@/assets/bg/OURPROJ.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.OP{
  height: 100%;
  width: 100%;
  display: flex;
  background: rgba(0, 0, 0, 0.8);
  flex-direction: column;
}
.hdr {
  width: 30vw;
  height: 7vh;
  border-bottom: 1vh solid white;
  display: flex;
  align-items:center;
  justify-content: center;
}
h1{
  margin: 2vh;
  font-size: 3vw;
  color: white; 
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; 
}
.content{
  height: 90%;
  width: 100%;
  display: flex;
  position: relative;
  align-items:center;
  justify-content: center;
}
.navBut{
  flex: 1;
  display: flex;
  position: relative;
  align-items:center;
  justify-content: center;
}
.back-button {
  border: none;
  background-color: transparent; /* Adjust the color to match the button background */
  border-radius: 50%; /* Makes the button round */
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease; /* Smooth background color transition on hover */
  width: 7vw;
  height: auto;
}
.back-button:hover {
  background-color: #e69500; /* Darker shade for hover state */
  width: 7vw;
  height: auto;
}
.back-button img {
  width: 7vw;
  height: auto;
}
.forward-button {
  flex: 0.4;
  border: none;
  background-color: transparent; /* Adjust the color to match the button background */
  border-radius: 50%; /* Makes the button round */
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease; /* Smooth background color transition on hover */
  width: 7vw;
  height: auto;
}
.forward-button:hover {
  background-color: #e69500; /* Darker shade for hover state */
  width: 7vw;
  height: auto;
}
.forward-button img {
  width: 7vw;
  height: auto;
}
.iconAndButton {
  height: auto;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.iconAndButton img {
  height: 73vh;
  width: auto;
  margin-bottom: 2%;
}
.cta-button {
  margin: 2%;
  background-color: #FFA500;
  color: #060606; /* White text */
  border: none; /* No border */
  font-family: Arial, sans-serif; /* Default font, adjust as needed */
  padding: 1vh 2.5vw; /* Use viewport units to scale padding */
  font-size: 1.7vw;
  width: 70%;
  font-weight: bold; /* Bold font */
  text-transform: uppercase; /* Uppercase text */
  border-radius: 30px; /* Rounded corners, adjust as needed */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow effect, adjust as needed */
  cursor: pointer; /* Cursor changes to a hand symbol when hovering over the button */
  transition: all 0.3s ease; /* Smooth transition for hover effects */
}
</style>

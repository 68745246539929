<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App'
}
  console.log(navigator.userAgent);
</script>

<style>
#app {
  width: 100%;
  margin: 0;
}
html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
</style>

<template>
    <footer>
      <div class="footer-container">
        <div class="footer-about">
          <p>We provide high-quality home repair services.<br>Our specialists have many years of experience and guarantee top-quality work.</p>
        </div>
        <div class="footer-links">
          <h4>Quick Links:</h4>
          <ul>
            <li><router-link to="/" class="navbar-item">Home</router-link></li>
            <li><router-link to="/aboutUs" class="navbar-item">About Us</router-link></li>
            <li><router-link to="/services" class="navbar-item">Services</router-link></li>
          </ul>
        </div>
        <div class="footer-contact">
          <h4>Contacts:</h4>
          <div class="footer-contact-text">
            <p>Phone: (305)290-9589</p>
            <p>Email: vscustomr@gmail.com</p>
            <p>Address: 3050 Garfield St, Hollywood, FL 33021</p>
          </div>
        </div>
      </div>
      <div class="footer-legal">
        <p>&copy; 2024 VS Custom Remodeling. All rights reserved. <router-link to="/PolicyAndTerms">Disclaimer</router-link>.</p>
      </div>
    </footer>
  </template>
  
  <script>
  export default {
    name: 'FooterEl'
  }
  </script>
  
  <style scoped>
  .footer{
    width: 100vw;
    height: 5vw;
  }
  .footer-container {
    padding: 0.5%;
    display: flex;
    justify-content: space-between;
    background-color: #ffa500;
    color: #080808;
  }
  .footer-links, .footer-contact, .footer-about{
    flex: 1;
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  .footer-about p{
    font-size:medium;
  }
  .footer-links h4 {
    margin-right: 2%;
    font-size: medium;
  }
  .footer-links ul {
    text-align: left;
    margin-left: 1%;
    flex-direction: column;
    margin-top: 0;
    margin-bottom: 0;
    list-style-type: none;
    padding: 0;
    font-size:medium;
  }
  .footer-links ul li {
    margin-bottom: 3px;
  }
  .footer-links ul li a {
    color: #000000;
    text-decoration: underline;
  }
  .footer-links ul li a:hover {
    text-decoration: underline;
  }
  .footer-contact h4{
    margin-right: 2%;
    font-size: medium;
  }
  .footer-contact-text{
    flex-direction: column;
    text-align: left;
 }
  .footer-contact P{
    margin-bottom: 3px;
    font-size:medium;
    margin-top: 0;
  }
  .footer-legal {
    padding-bottom: 0.5%;
    text-align: center;
    background-color: #ffa500;
  }
  .footer-legal p {
    font-size: medium;
    margin-top: 0;
    margin-bottom: 0;
  }
  .footer-legal a {
    color: #000000;
    text-decoration: underline;
  }
  .footer-legal a:hover {
    text-decoration: underline;
  }
  </style>
  
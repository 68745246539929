<template>
    <div class="FB">
        <div class="fbText">
            <h1>FEEDBACKS</h1>
        </div>
        <div class="fbmain">
            <transition name="fade-up" mode="out-in">
                    <img v-if="currentIcon" class="imgFB" :src="currentIcon" :key="currentIcon">
            </transition>
            <button class="cta-button" @click="submitRequest">Submit a request</button>
        </div>
    </div>

</template>

<script>

import iconfb from '@/assets/feedbacks/fbicon.png'
import iconfb1 from '@/assets/feedbacks/fbicon1.png'
import iconfb2 from '@/assets/feedbacks/fbicon2.png'
import iconfb3 from '@/assets/feedbacks/fbicon3.png'
import iconfb4 from '@/assets/feedbacks/fbicon4.png'
import iconfb5 from '@/assets/feedbacks/fbicon5.png'
export default {
  name: 'FeedBacksHome',
  data () {
    return {
      icons: [
            iconfb,
            iconfb1,
            iconfb2,
            iconfb3,
            iconfb4,
            iconfb5
            ],
      currentIconIndex: 0
    }
  },
  computed: {
        currentIcon() {
            return this.icons[this.currentIconIndex];
        }
  },
  methods: {
    submitRequest () {
      this.$router.push({ name: 'Home' }).then(() => {
        this.$nextTick(() => {
          const element = document.getElementById('request-form')
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' })
          }
        })
      })
    },
    switchIcon() {
            this.currentIconIndex = (this.currentIconIndex + 1) % this.icons.length;
        },
    startCarousel() {
      setInterval(this.nextIcon, 5000); // Change image every 20 seconds
    }
  },
  mounted() {
        setInterval(this.switchIcon, 15000);
    }
}

</script>

<style scoped>

.FB{
    height: 100vh;
    display: flex;
    flex-direction: row;
}

.fbText{
    flex: 6%;
    background-color:rgb(201, 199, 199);
    position: relative;
    align-items:center;
    justify-content: center;
    display: flex;
    overflow: hidden;
}
h1{
    font-size: 15vh;
    color: dimgray;
    letter-spacing: 0.05em;
    transform: rotate(270deg);
}
.fbmain{
      height: 100vh;
      flex: 91%;
      background-image: url('@/assets/bg/FB.png');
      background-size: cover;
      background-position: center;
      display: flex;
      position: relative;
      align-items:center;
      justify-content: center;
      flex-direction: column;
}
.fbicon{
    width: 100%;
    height: 85vw;
    display: flex;
    position: relative;
    flex-direction: column;
}
.imgFB{
    margin-bottom: 6%;
    width: 60%;
    height: auto;
}
.cta-button {
  bottom: 5%;
  position: absolute;
  margin: 2%;
  background-color: #FFA500;
  color: #060606; /* White text */
  border: none; /* No border */
  font-family: Arial, sans-serif; /* Default font, adjust as needed */
  padding: 1vh 2.5vw; /* Use viewport units to scale padding */
  font-size: 1.7vw;
  width: 27%;
  font-weight: bold; /* Bold font */
  text-transform: uppercase; /* Uppercase text */
  border-radius: 30px; /* Rounded corners, adjust as needed */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow effect, adjust as needed */
  cursor: pointer; /* Cursor changes to a hand symbol when hovering over the button */
  transition: all 0.3s ease; /* Smooth transition for hover effects */
}
.fade-up-enter-active, .fade-up-leave-active {
    transition: opacity 0.9s, transform 0.9s;
    transition-timing-function: ease;
}
.fade-up-enter, .fade-up-leave-to /* начальное и конечное состояние анимации */ {
    opacity: 0;
    transform: translateY(-30%); /* Элемент движется вниз на 100% своей высоты */
}
</style>

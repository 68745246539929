<template>
  <div class="mainClass">
    <NavLinkBarContacts  text-color="black" class="NavLinkBar"></NavLinkBarContacts>
    <div class="divText">
      <h1 class="contT">CONTACTS</h1>
    </div>
    <div class="mainContent">
            <div class="infBox">
                <img :src="location" alt="locationLogo">
                <h1 class="mainText">ADDRESS</h1>
                <h1 class="minorText">3050 Garfield St, Hollywood, FL 33021</h1>
            </div>
            <div class="infBox">
                <img class="mailImage" :src="email" alt="mailLogo">
                <h1 class="mainText">EMAIL</h1>
                <h1 class="minorText">vscustomr@gmail.com</h1>
            </div>
            <div class="infBox">
                <img :src="phone" alt="phoneLogo">
                <h1 class="mainText">MOBILE</h1>
                <h1 class="minorText">(305)290-9589</h1>
            </div>
    </div>
    <div class="forButt">
         <button class="cta-button"  @click="submitRequest">Submit a request</button>
    </div>
  </div>
  <FooterEl></FooterEl>
</template>

<script>

import NavLinkBarContacts from '@/components/navbar/NavLinkBar.vue'
import loc from '@/assets/contacts/locationLogo.png' // Adjust the path to your logo image
import mail from '@/assets/contacts/mailLogo.png' // Adjust the path to your logo image
import phon from '@/assets/contacts/phoneLogo.png' // Adjust the path to your logo image
import FooterEl from '@/components/footerAndLeg/Footer.vue'

export default {
  name: 'ContactsPage',
  mounted () {
    document.title = 'Contact Us'
  },
  components: {
    NavLinkBarContacts,
    FooterEl
  },
  methods: {
    submitRequest () {
      this.$router.push({ name: 'Home' }).then(() => {
        this.$nextTick(() => {
          const element = document.getElementById('request-form')
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' })
          }
        })
      })
    }
  },
  data () {
    return {
      location: loc,
      email: mail,
      phone: phon
    }
  }
}
</script>

<style scoped>
.mainClass{
    user-select: none;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: flex-start; /* This will align your .content div to the left */
    background: transparent;
    flex-direction: row;
}
.NavLinkBar {
  top: 0;
  left: 0;
  width: 100%;
  height: 25%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  position: absolute;
}
.divText{
    margin-top: 20vh;
    background-color: black;
    width: 100vw;
    height: 15vh;
    display: flex;
    align-items: center; /* This will vertically center your .content div */
    justify-content: flex-start; /* This will align your .content div to the left */
    background-color: black;
    position: absolute;
}
h1{
    font-family: 'Hedvig Letters Serif', serif;
}
.contT{
    color: white;
    font-size: 6vw;
    margin: 0;
    margin-left: 1vw;
}

.mainContent{
  position: absolute;
  margin-top: 35vh;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
}
.infBox{
    margin-top: 5vh;
    flex: 2;
    height: 30vh;
    width: 45vw;
    margin-left: 3vw;
    margin-right: 3vw;
    border: 4px solid orange;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
}
.infBox img{
  width: auto;
  height: 14vh;
  margin: 3%;
}
.infBox .minorText{
    text-align: center;
    font-size: 3vh;
    font-weight: bold;
}
.infBox .mainText{
  font-size: 3vh;
  margin: 0;
}
.infBox .mailImage{
    width: auto;
    height: 15vh;
    margin: 0;
}
.forButt{
    margin-top: 76vh;
    width: 100%;
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cta-button {
  background-color: #FFA500;
  color: #060606; /* White text */
  border: none; /* No border */
  font-family: Arial, sans-serif; /* Default font, adjust as needed */
  padding: 3vh 3.5vw; /* Use viewport units to scale padding */
  font-size: 2vw;
  width: 30%;
  font-weight: bold; /* Bold font */
  text-transform: uppercase; /* Uppercase text */
  border-radius: 30px; /* Rounded corners, adjust as needed */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow effect, adjust as needed */
  cursor: pointer; /* Cursor changes to a hand symbol when hovering over the button */
  transition: all 0.3s ease; /* Smooth transition for hover effects */
}
.FooterEl {
  width: 100%;
  display: flex;
  position: absolute;
  align-items: stretch;
}
</style>

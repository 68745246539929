<template>
    <div class="mainClass">
      <NavLinkBar class="NavLinkBar"></NavLinkBar>
        <div class="nav_page">
          <h1 class="maintitle">EXTERIOR WORKS</h1>
          <h3 class="minititle">Patio Construction</h3>
          <div class="arrow-icon" @click="navigateToPage()"></div>
        </div>
        <div class="mainContent">
          <div class="img">
            <img :src="img1">
            <img :src="img2">
          </div>
          <div class="text">
          <div class="txt">
            <h4>
              Step into a world of outdoor comfort and style with our premium patio construction<br>
              services. Whether you envision a serene space for relaxation, a vibrant<br>
              entertainment area, or a stylish extension of your home, our expert team is<br>
              dedicated to crafting the perfect patio to suit your lifestyle.<br><br>
              Our commitment to excellence is reflected in every detail of our patio construction<br>
              projects. From the initial design phase to the final touches, we prioritize quality<br>
              materials and meticulous craftsmanship to ensure your patio is not only visually<br>
              stunning but also built to withstand the elements.
            </h4>
          </div>
            <div class="button-container"> <!-- New wrapper for the button -->
              <button class="cta-button" @click="submitRequest">Submit a request</button>
            </div>
          </div>
        </div>
    </div>
  </template>

<script>

import NavLinkBar from '@/components/navbar/NavLinkBar.vue'
import im1 from '@/assets/extWork/PatCon1.png'
import im2 from '@/assets/extWork/PatCon2.png'
export default {
  name: 'PCPage',
  components: {
    NavLinkBar
  },
  data () {
    return {
      // Use the imported 'iconfb' directly instead of 'fbi'
      img1: im2,
      img2: im1
    }
  },
  methods: {
    submitRequest () {
      this.$router.push({ name: 'Home' }).then(() => {
        this.$nextTick(() => {
          const element = document.getElementById('request-form')
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' })
          }
        })
      })
    },
    navigateToPage () {
      this.$router.push({ name: 'ExteriorWorks' })
    }
  }
}
</script>

  <style scoped>
  .mainClass{
    user-select: none;
    height: auto;
    width: 100vw;
    display: flex;
    background-image: linear-gradient( rgba(0, 0, 0, 0.5),  rgba(0, 0, 0, 0.5)), url('@/assets/bg/EW.png');
    background-size: cover;
    flex-direction: column;
  }
  .NavLinkBar {
    top: 0;
    left: 0;
    width: 100%;
    height: 25%;
    display: flex;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.01) 100%);
    color: white; /* Assuming you want white text for contrast */
    position: absolute;
    align-items: stretch;
}
  .nav_page {
    margin-top: 23vh;
    display: flex;
    width: 100%;
    height: 20%;
    background-color: rgb(255, 166, 0);
    display: flex;
    align-items: center;
    justify-items: center;
  }
  .maintitle{
    flex: 5;
    color: #fff;
    margin: 1%;
    margin-left: 1vw;
    text-align: left;
    font-size: 9vh;
  }
  .minititle{    
    margin: 0;
    flex: 5;
    color: #fff;
    font-size: 6vh;
  }
  .arrow-icon {
    position: absolute;
    margin-right: 1%;
    right: 1vw; /* Position the arrow 1vw from the right edge */
    width: 3.7vw; /* Arrow size */
    height: 7.4vh; /* Arrow size */
    border: 1vh solid rgb(255, 255, 255); /* Circle border */
    border-radius: 50%; /* Make it round */
    display: flex;
    align-items: center;
    justify-content: center;
}

.arrow-icon:before {
    content: '';
    display: block;
    width: 1.6vw; /* Arrow width */
    height: 1.6vw; /* Arrow height */
    border-left: 1vh solid rgb(255, 255, 255); /* Right part of arrow */
    border-bottom: 1vh solid rgb(255, 255, 255); /* Top part of arrow */
    transform: rotate(45deg); /* Angle the arrow */
    position: relative;
    z-index: 0; /* Ensure arrow is above the stick */
}

.arrow-icon:after {
    content: '';
    display: block;
    position: absolute;
    width: 2.2vw; /* Stick width */
    height: 1vh; /* Stick height */
    background-color: rgb(255, 255, 255); /* Stick color */
    left: 1vw;
    top: 50%; /* Positioning at the center of the circle */
    right: 50%;
    transform: translateY(-50%); /* Ensuring it's centered vertically */
    z-index: 0; /* Below the arrow head */
}
  .mainContent{
    width: 100%;
    background: #dbdada;
    flex-direction: column;
    display: flex;
    height: auto;
  }
  .img{
    flex-direction: row;
    display: flex;
    height: 55vh;
    width: 100%;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
  }
  .img img {
    height: 90%; /* This makes the image's height 70% of its parent (.img), which is what you asked for */
    width: auto; /* This ensures the width adjusts automatically to maintain the aspect ratio */
  }
  .text{
    flex: 5;
    display: flex; /* Use flexbox to center the image inside .img */
    flex-direction: column;
    justify-content: center;
    font-family: "Roboto Mono", monospace;
  }
  .txt{
    justify-content: center;
    display: flex; /* Use flexbox to center the image inside .img */
  }
  h4{
    width: 700%;
    font-size: 3.4vh;
    text-align: center;
  }
  .button-container {
    display: flex;
    justify-content: center; /* Keeps the button aligned to the left */
    align-items: center; /* Keeps items aligned to the top, if necessary */
    width: 100%;
    margin-bottom: 3%;
  }
  .cta-button {
    margin: 1%;
    background-color: #FFA500;
    color: #060606; /* Ensures text color is dark for contrast */
    border: none; /* Removes border */
    font-family: Arial, sans-serif; /* Specifies the font family */
    padding: 1vh 2.5vw; /* Adjusts padding based on viewport units */
    font-size: 1.6vw; /* Adjusts font size based on viewport width */
    width: auto; /* Adjusts width to fit content */
    font-weight: bold; /* Applies bold font styling */
    text-transform: uppercase; /* Transforms text to uppercase */
    border-radius: 30px; /* Applies rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adds shadow for depth */
    cursor: pointer; /* Changes cursor to pointer on hover */
    transition: all 0.3s ease; /* Smoothens the transition effects */
  }
  </style>

<template>
    <div class="about-us">
      <div class="first">
        <div class="h1au">
          <h1>ABOUT US</h1>
        </div>
        <div class="statistics">
          <img :src="statist" alt="Stat">
        </div>
      </div>
      <div class="second">
        <div class="our-licence">
          <div class="h1ol"><h1>OUR LICENCE</h1></div>
          <div class="licences">
            <img :src="lic1url" alt="Licence 1">
            <img :src="lic2url" alt="Licence 2">
          </div>
        <button class="more-about-us" @click="moreAU">MORE ABOUT US →</button>
        </div>
      </div>
    </div>
</template>
<script>

import lic1 from '@/assets/licence/lice1.png' // Adjust the path to your logo image
import lic2 from '@/assets/licence/lice2.png' // Adjust the path to your logo image
import stat from '@/assets/aboutUs/stat.png' // Adjust the path to your logo image

export default {
  name: 'AboutUsHome',
  // You can add your Vue logic here
  data () {
    return {
      lic1url: lic1,
      lic2url: lic2,
      statist: stat
    }
  },
  methods: {
    moreAU () {
      this.$router.push({ name: 'AboutUs' })
    }
  }
}
</script>

<style scoped>

.about-us{
  height: 80vh;
  width: 100%;
  flex-direction: row;
  background-color: #252525; /* Dark background */
  background-position: center;
  display: flex;
  align-items:center;
}
.first{
  height: 100%;
  width: 33%;
}
.second{
  flex: 7;
}
.h1au {
  width: 100%;
  font-size: 3.5vh;
  align-items: center;
  text-align: center;
  justify-content: center;
  display: flex;
  color: #ffffff; /* Black text */
  text-transform: uppercase;
  margin-top: 4vh;
  margin-bottom: 2vh;
}
.h1au h1{
  margin: 0;
}
.statistics {
  padding-top: 0;
  height: auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
}

.statistics img {
  width: 55%; /* Image will scale within the container */
  height: auto; /* Maintain aspect ratio */
}
.our-licence {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
}
.licences {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}
.h1ol {
  margin-top: 2vh;
  margin-bottom: 2vh;
  width: 100%;
  font-size: 3.5vh;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  color: #ffffff; /* Black text */
}
.h1ol h1{
  margin: 0;
}
.licences img {
  height: auto;
  width: 40%;
  margin: 2%;
}

.more-about-us {
  width: 45%;
  background-color:orange;; /* Orange button */
  color: #000; /* Black text */
  border: none;
  border-radius: 30px;
  padding: 1.5% 1.5%;
  font-weight: bold;
  cursor: pointer;
  margin-top: 1%;
  margin-bottom: 2%;
  transition: background-color 0.3s ease;
}

.more-about-us:hover {
  background-color: #ffa500; /* Lighter orange on hover */
  height: 70%;
}
</style>

<template>
    <div class="main">
        <NavLinkBar class="NavLinkBar" text-color="white"></NavLinkBar>
        <div class="nav_page">
          <h1 class="maintitle">Privacy Policy and Terms of Service</h1>
        </div>
        <div class="mainContent">
                <div class="privacy-terms">
                <section class="privacy-policy">
                    <h2>Privacy Policy</h2>
                    <p>We value your privacy and are committed to protecting your personal data. This privacy policy will inform you about how we handle your personal data when you visit our website and use our services.</p>
                    
                    <h3>Data Collection</h3>
                    <p>We collect the following personal data from you:</p>
                      <div class="ll">
                        <div class="list">
                        <ul>
                        <li>Name</li>
                        <li>Surname</li>
                        <li>Phone Number</li>
                        <li>Email Address</li>
                        </ul>
                      </div>
                    </div>
                    
                    <p>This data is collected solely for feedback purposes and is not stored in any database or shared with third parties.</p>
                    
                    <h3>Data Usage</h3>
                    <p>The personal data you provide is used only to respond to your inquiries and provide the services you request.</p>
                    
                    <h3>Data Sharing</h3>
                    <p>We do not share your personal data with any third parties.</p>
                    
                    <h3>Data Security</h3>
                    <p>We implement appropriate security measures to protect your personal data from unauthorized access, disclosure, alteration, or destruction.</p>
                    
                    <h3>User Rights</h3>
                    <p>You have the right to access, modify, or delete your personal data at any time. To exercise these rights, please contact us at vscustomr@gmail.com.</p>
                </section>
                
                <section class="terms-of-service">
                    <h2>Terms of Service</h2>
                    <h3>Terms of Use</h3>
                    <p>By using our website and services, you agree to comply with and be bound by the following terms of service. Please read these terms carefully before using our website.</p>
                    
                    <h3>Limitation of Liability</h3>
                    <p>We are not responsible for any damages or losses resulting from your use of our website or services. Our liability is limited to the maximum extent permitted by law.</p>
                    
                    <h3>Copyright</h3>
                    <p>All content on this website, including text, graphics, logos, and images, is the property of our company and is protected by copyright laws. Unauthorized use of this content is prohibited.</p>
                    
                    <h3>Changes to Terms</h3>
                    <p>We reserve the right to modify these terms of service at any time. Any changes will be posted on this page. Your continued use of the website after any changes are made constitutes acceptance of the new terms.</p>
                    
                    <h3>Contact Information</h3>
                    <p>If you have any questions about these terms of service, please contact us at vscustomr@gmail.com</p>
                </section>
                </div>
                <FooterEl></FooterEl>
            </div>
        </div>
  </template>
  
  <script>
  import NavLinkBar from '@/components/navbar/NavLinkBar.vue'
import FooterEl from '@/components/footerAndLeg/Footer.vue'
  export default {
    name: 'PrivacyAndTerms',
    components: {
        NavLinkBar,
        FooterEl
    }
  }
  </script>

  <style scoped>
  .main{
    user-select: none;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: flex-start; /* This will align your .content div to the left */
    background: transparent;
  }
.NavLinkBar {
  top: 0;
  left: 0;
  width: 100%;
  height: 25%;
  display: flex;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.01) 100%);
  color: white; /* Assuming you want white text for contrast */
  position: absolute;
  align-items: stretch;
}
.nav_page {
    margin-top: 23vh;
    display: flex;
    width: 100%;
    height: 13%;
    background-color: rgb(255, 166, 0);
    display: flex;
    align-items: center;
    justify-items: center;
  }

  .maintitle{
    flex: 5;
    color: #fff;
    margin: 0;
    margin-left: 1vw;
    text-align: left;
    font-size: 9vh;
  }
.mainContent{
  position: absolute;
  margin-top: 35vh;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.privacy-terms {
    text-align: center;
    background-color: #fff;
    color: #333;
    font-family: Arial, sans-serif;
    padding-top: 3%;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 3%;
  }
  .list{
    text-align: left;
  }
  .ll{
    transform: translate(43%);
  }
  .privacy-terms h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .privacy-terms h2 {
    margin-top: 2%;
  }
  
  .privacy-terms h3 {
    margin-top: 20px;
  }
  
  .privacy-terms p, .privacy-terms ul {
    margin: 10px 0;
  }
  
  .privacy-terms ul {
    padding-left: 20px;
  }
  
  .privacy-terms ul li {
    list-style-type: disc;
  }
  .FooterEl {
  width: 100%;
  display: flex;
  position: absolute;
  align-items: stretch;
}
  </style>
  
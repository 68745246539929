<template>
    <div class="mainClass">
        <NavLinkBar class="NavLinkBar"></NavLinkBar>
        <div class="mainContent">
            <div class="first">
                <img :src="pic" class="fam">
                <div class="statistics">
                    <div class="stat">
                        <h2 class="statText">225</h2>
                        <p>Happy customrs</p>
                    </div>
                    <div class="stat">
                        <h2 class="statText">635+</h2>
                        <p>Hours invested in projects</p>
                    </div>
                    <div class="stat">
                        <img :src="inf" class="infi">
                        <p>Love for our customers</p>
                    </div>
                </div>
            </div>
            <div class="second">
                <h1>ABOUT OUR COMPANY</h1>
                <p> We are family owned business founded in<br>
                    the USA in 2016. With over 20 years of<br>
                    experience in the construction industry.<br>
                    Over the years, we have satisfied over 300<br>
                    clients and successfully completed more<br>
                    than 60 major renovations.<br>
                    Our construction company offers a full<br>
                    range of services, from small household<br>
                    repairs to large-scale construction<br>
                    projects.</p>
                <div class="btn">
                  <div class="forButt">
                    <button class="cta-button" @click="submitRequest">Submit a request</button>
                  </div>
                </div>
            </div>
        </div>
    </div>
  <FooterEl></FooterEl>
</template>

<script>

import NavLinkBar from '@/components/navbar/NavLinkBar.vue'
import famPic from '@/assets/aboutUs/familyPyc.png' // Adjust the path to your logo image
import infPic from '@/assets/aboutUs/infinity.png' // Adjust the path to your logo image
import FooterEl from '@/components/footerAndLeg/Footer.vue'
export default {
  name: 'AboutUsPage',
  mounted () {
    document.title = 'About Us'
  },
  components: {
    NavLinkBar,
    FooterEl
  },
  data () {
    return {
      pic: famPic,
      inf: infPic
    }
  },
  methods: {
    submitRequest () {
      this.$router.push({ name: 'Home' }).then(() => {
        this.$nextTick(() => {
          const element = document.getElementById('request-form')
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' })
          }
        })
      })
    }
  }
}
</script>

<style scoped>
.mainClass{
  background: rgba(0, 0, 0, 0.8);
  user-select: none;
  height: 100%;
  width: 100vw;
  display: flex;
  justify-content: space-between; /* This will align your .content div to the left */
  background-image: url('@/assets/bg/bgAboutUs.png');
  background-size: cover;
  background-position: center;
  flex-direction: row;
  flex-grow: 1; /* Expand to fill available space */
}
.NavLinkBar {
  width: 100%;
  height: 25%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  position: absolute;

}
.mainContent{
  margin-top: 25vh;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-bottom: 5%;
}

.first {
  margin-top: 2vh;
  display: flex;
  flex: 7;
  height: 100%;
  align-items: center;
  flex-direction: column;
}

.fam{
  margin-top: 1vh;
  width: 80%;
  height: auto;
}
.statistics {
  padding-top: 0;
  width: 95%;
  height: 23vh;
  justify-content: center;
  align-items: center;
  flex-direction:row;
  display: flex;
}
.stat{
  width: 20vw;
  height: 15vh;
  text-align: center;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 10px;
  flex-direction:column;
  margin-left: 2.5%;
  margin-right: 2.5%;
  background-color: orange;
  text-align: center; /* Center the text horizontally */
  display: flex;
  position: relative;
}
.stat p {
  margin: 0;
  flex: 1;
  font-size: 2.8vh;
  font-weight:bold;
  flex: 1;
  text-align: center;
  color: black; /* Black text */
}
.statText{
  margin: 0;
  font-size: 6.5vh;
  flex: 2;
  text-align: center;
  text-transform: uppercase;
  align-items: center;
  flex-direction:row;
  display: flex;
  color: white; /* Black text */
}
.infi{
  margin-top: 7%;
  margin-bottom: 5%;
  height: 5.6vh;
  width: auto;
}
.second{
  margin-left: 2vw;
  margin-top: 1%;
  flex:5;
  height: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
}
h1{
  color: orange;
  font-size: 3vw;
}
.second p{
  text-align: left;
  color: white;
  font-size: 1.7vw;
}
.btn{
  width: 100%;
  display: flex;
  justify-content: center;
}
.forButt{
  margin-top: 7%;
  display: flex;
  width: 25vw;
  height: 10vh;
}
.cta-button {
  background-color: #FFA500;
  color: #060606; /* White text */
  border: none; /* No border */
  font-family: Arial, sans-serif; /* Default font, adjust as needed */
  padding: 1vh 2.5vw; /* Use viewport units to scale padding */
  font-size: 1.6vw;
  width: 100%;
  font-weight: bold; /* Bold font */
  text-transform: uppercase; /* Uppercase text */
  border-radius: 30px; /* Rounded corners, adjust as needed */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow effect, adjust as needed */
  cursor: pointer; /* Cursor changes to a hand symbol when hovering over the button */
  transition: all 0.3s ease; /* Smooth transition for hover effects */
}
.FooterEl {
  width: 100%;
  display: flex;
  position: absolute;
  align-items: stretch;
}
</style>

<template>
    <nav class="navbar">
      <div class="navbar-brand">
        <img :src="curentLogo" alt="V&S Custom Remodeling Logo">
      </div>
      <ul class="navbar-menu">
        <router-link to="/" class="navbar-item" exact-active-class="router-link-active" :style="{ color: textColor }">Home</router-link>
        <router-link to="/aboutUs" class="navbar-item" exact-active-class="router-link-active" :style="{ color: textColor }">About us</router-link>
        <div class="navbar-item dropdown">
          <router-link to="/services" class="navbar-item dropdown-trigger" :class="{'custom-active': isServicesSection}" :style="{ color: textColor }">Services</router-link>
          <div class="dropdown-menu">
            <router-link to="/services/ExteriorWorks" class="dropdown-item"  exact-active-class="router-link-active" :style="{ color: textColor }">
              Exterior Works
              <div class="nested-dropdown-menu">
                <router-link to="/services/ExteriorWorks/ExteriorDecoration" class="nested-dropdown-item" :style="{ color: textColor }">Exterior Decor</router-link>
                <router-link to="/services/ExteriorWorks/ConcreteWorks" class="nested-dropdown-item" :style="{ color: textColor }">Concrete Works</router-link>
                <router-link to="/services/ExteriorWorks/PatioConstruction" class="nested-dropdown-item" :style="{ color: textColor }">Patio Construction</router-link>
                <router-link to="/services/ExteriorWorks/ExteriorPaintin" class="nested-dropdown-item" :style="{ color: textColor }">Exterior Paintin</router-link>
              </div>
            </router-link>
            <router-link to="/services/InteriorWorks" class="dropdown-item"  exact-active-class="router-link-active" :style="{ color: textColor }">
              Interior Works
              <div class="nested-dropdown-menu">
                <router-link to="/services/InteriorWorks/HomeRemodelingAndRenovation" class="nested-dropdown-item" :style="{ color: textColor }">Home Remodeling & Renovation</router-link>
                <router-link to="/services/InteriorWorks/HomeExtention" class="nested-dropdown-item" :style="{ color: textColor }">Home extension</router-link>
                <router-link to="/services/InteriorWorks/InteriorPainting" class="nested-dropdown-item" :style="{ color: textColor }">Interior Painting</router-link>
                <router-link to="/services/InteriorWorks/KitchenBathroom" class="nested-dropdown-item" :style="{ color: textColor }">Kitchen And Bathroom Remodel</router-link>
                <router-link to="/services/InteriorWorks/PlumbingWorkFixtureInst" class="nested-dropdown-item" :style="{ color: textColor }">Plumbing Work And Plumbing Fixture Installation</router-link>
                <router-link to="/services/InteriorWorks/KitchenCabinets" class="nested-dropdown-item" :style="{ color: textColor }">Kitchen Cabinets</router-link>
                <router-link to="/services/InteriorWorks/WaterInstrep" class="nested-dropdown-item" :style="{ color: textColor }">Water Heater Installation & Water Piping Repair</router-link>
                <router-link to="/services/InteriorWorks/WindowsDoors" class="nested-dropdown-item" :style="{ color: textColor }">Window And Doors</router-link>
              </div>
            </router-link>
            <router-link to="/services/PermitExpeditorLegalizations" class="dropdown-item"  exact-active-class="router-link-active" :style="{ color: textColor }">
              PermitExpeditorLegalizations
              <div class="nested-dropdown-menu">
                <router-link to="/services/PermitExpeditorLegalizations/PermitRunning" class="nested-dropdown-item" :style="{ color: textColor }">Permit Running</router-link>
                <router-link to="/services/PermitExpeditorLegalizations/EPM" class="nested-dropdown-item" :style="{ color: textColor }">Electrical, Plumbing G permits, Mechanical Permits</router-link>
                <router-link to="/services/PermitExpeditorLegalizations/LegalizingAfterFactWork" class="nested-dropdown-item" :style="{ color: textColor }">Legalizing After the Fact Work</router-link>
              </div>
            </router-link>
          </div>
        </div>
        <router-link to="/contacts" class="navbar-item" exact-active-class="router-link-active" :style="{ color: textColor }">Contacts</router-link>
      </ul>
      <div class="navbar-end">
        <button class="button is-rounded tel-button" :style="{ color: textColor }">
          (305)290-9589
        </button>
      </div>
    </nav>
  </template>

<script>
import Logo from '@/assets/mainLogo/Logo4.png' // Adjust the path to your logo image
import LogoBlack from '@/assets/mainLogo/logoBlack.png' // Adjust the path to your logo image

export default {
  name: 'CustomNavbar',
  // Your Vue logic here
  props: {
    textColor: {
      type: String,
      default: 'white' // default color
    }
  },
  computed: {
    curentLogo () {
      if (this.$route.path === '/contacts') {
        return LogoBlack
      }
      return Logo
    },
    isServicesSection () {
      return this.$route.path.startsWith('/services')
    }
  }
}
</script>

<style scoped>
.navbar {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  color: #fff;
}
.navbar > .navbar-menu,
.navbar > .navbar-end {
  flex: 1;
  text-align: center; /* This will center the content inside each section */
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar > .navbar-brand {
  flex: 1; /* This will make each child take up equal space */
  display: flex;
}

.navbar-brand img {
    margin-left: 4vw;
    max-width: 80%; /* Sets the image width to be 75% of the div */
    max-height: 80%; /* Sets the image height to be 75% of the div */
    object-fit: contain; /* Ensures the aspect ratio is maintained */
}
.navbar-menu {
  list-style-type: none;
  display: flex;
  justify-content: center; /* Centers the menu items horizontally */
  align-items: center; /* Centers the menu items vertically */
  padding: 0;
  margin: 0; /* Remove default margin */
  width: 10vw;
  object-fit: contain;
}

.navbar-item {
    flex-grow: 1; /* Grow to fill the available space */
    text-align: center; /* Center text within each navbar item */
    white-space: nowrap; /* Prevent text wrapping */
}
.navbar-item{
  text-decoration: none;
  display: blok; /* Make the anchor fill the padding for a larger clickable area */
  font-size: 1.5vw; /* Increase the font size */
  padding: 1vw; /* Add padding to increase the size and improve spacing */
  margin: 0; /* Remove default margin */
  transition: color 0.3s; /* Smooth transition for hover effects */
}
.router-link-active {
  text-decoration: none; /* Remove the default underline */
  display: block; /* Make the anchor fill the padding for a larger clickable area */
  padding: 0.5rem 1rem; /* Add padding to increase the size and improve spacing */
  position: relative; /* Needed for positioning the pseudo-element */
  transition: color 0.3s; /* Smooth transition for hover effects */
}

.router-link-active::after {
  content: ''; /* Necessary for the pseudo-element to show */
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0; /* Align with the bottom of the text */
  border-bottom: 0.5vh solid orange; /* Custom underline thickness and color */
  width: 100%; /* Ensure the underline spans the full width of the text */
  margin-top: 3px; /* Space between the text and the underline */
}
.custom-active {
  border-bottom: 3px solid orange; /* Custom underline thickness and color */
}
div.navbar-end {
    display: flex;
    justify-content: flex-end;
    height: 100%;
  }

  .tel-button {
    width: 65%;
    height: 40%;
    background-color: #ffa60018; /* Button background color */
    color: #ffa500; /* Text color */
    padding: 1vh 2vw; /* Padding around text */
    border: 2px solid #ffa500; /* Border color and width */
    border-radius: 50px; /* Rounded corners */
    font-size: 2vw; /* Text size */
    font-weight: bold; /* Text weight */
    text-align: center;
    cursor: pointer; /* Cursor to display on hover */
    outline: none; /* Remove default focus outline */
  }

  .tel-button {
  transition: all 0.3s ease; /* Smooth transition for the hover effect */
  /* Other styling for the button */
}

.tel-button:hover {
  transform: translateY(2px); /* Moves the button down slightly */
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2); /* Adjusts the shadow to give depth */
  background-color: #ffa60098;
}
.navbar-item.dropdown {
  position: relative;
}

.dropdown-menu {
  display: none; /* Initially hidden */
  position: absolute;
  left: 0; /* Align to the left of the .dropdown */
  top: 100%; /* Position just below the dropdown trigger */
  background-color: rgba(14, 13, 13, 0.589);
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  z-index: 100; /* Ensure it's above other content */
  width: max-content; /* Adjust width as needed */
  transform: translateY(-10px);
  transition: opacity 0.5s ease, transform 0.5s ease;
  opacity: 0;
  visibility: hidden;
}
.navbar-item.dropdown:hover .dropdown-menu {
  display: block;
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
}

.dropdown-item {
  color: #000;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  transition: background-color 0.3s;
}

.dropdown-item:hover {
  background-color:  #dddddd69; /* Feedback for hover */
}
.nested-dropdown-menu {
  display: none; /* Initially hidden */
  position: absolute;
  left: 100%; /* Position to the right of the parent item */
  top: 0; /* Align with the top of the parent item */
  background-color: rgba(14, 13, 13, 0.795);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 101; /* Ensure it appears above the first-level dropdown */
  width: max-content; /* Adjust width as needed */
}

/* Show nested dropdown on hover of the parent item */
.dropdown-item:hover .nested-dropdown-menu {
  display: block; /* Make visible */
}

.nested-dropdown-item {
  color: #000;
  padding: 12px 16px;
  text-decoration: none;
  display: block; /* Ensures full width is clickable */
  transition: background-color 0.3s;
}

.nested-dropdown-item:hover {
  background-color: #dddddd69; /* Feedback for hover */
}
</style>
